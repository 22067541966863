// components/InternetPlans.js
import React from 'react';

function InternetPlans() {
  const plans = [
    { id: 1, name: 'Plan Básico', speed: '10 Mbps', price: 499 },
    { id: 2, name: 'Plan Estándar', speed: '30 Mbps', price: 799 },
    { id: 3, name: 'Plan Premium', speed: '50 Mbps', price: 999 },
    { id: 4, name: 'Plan Ultra', speed: '100 Mbps', price: 1499 },
  ];

  return (
    <div>
      <h2>Planes de Internet</h2>
      <div className="plan-list">
        {plans.map((plan) => (
          <div key={plan.id} className="plan">
            <h3>{plan.name}</h3>
            <p>Velocidad: {plan.speed}</p>
            <p>Precio: RD${plan.price} / mes</p>
            <button>Seleccionar Plan</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InternetPlans;
