// components/Home.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import fiberOpticImage1 from '../images/fiber-optic-1.jpg';
import fiberOpticImage2 from '../images/fiber-optic-2.jpg';
import fiberOpticImage3 from '../images/fiber-optic-3.jpg';
import faker from 'faker';

function Home() {
  // Configuración del slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <h2>Bienvenido a Wel Net</h2>

      {/* Slider de imágenes de fibra óptica */}
      <Slider {...sliderSettings}>
        <div>
          <img src={fiberOpticImage1} alt="Fibra Óptica 1" />
        </div>
        <div>
          <img src={fiberOpticImage2} alt="Fibra Óptica 2" />
        </div>
        <div>
          <img src={fiberOpticImage3} alt="Fibra Óptica 3" />
        </div>
      </Slider>

      <p>{faker.company.catchPhrase()}</p>
      <p>{faker.company.bs()}</p>
      <p>{faker.lorem.paragraph()}</p>
      <p>Contacto: {faker.internet.email()}</p>
      <p>Dirección: {faker.address.streetAddress()}</p>
    </div>
  );
}

export default Home;
