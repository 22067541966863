// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPen, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Home from './components/Home';
import Products from './components/Products';
import Login from './components/Login';
import Register from './components/Register';
import InternetPlans from './components/InternetPlans';
import OnlinePayments from './components/OnlinePayments';
import ChatComponent from './components/ChatComponent';
import FooterMenu from './components/FooterMenu';
import OverduePayments from './components/OverduePayments'; // Importa el componente

function App() {
  const [showChat, setShowChat] = useState(false);

  return (
    <Router>
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary" style={{ background: 'linear-gradient(to right, #001935, #003366)' }}>
          <div className="container">
            <Link className="navbar-brand" to="/">Well Net</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Inicio</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/products">Productos</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/internet-plans">Planes de Internet</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/online-payments">Pagos en Línea</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/overdue-payments">Pagos en atraso</Link>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    <FontAwesomeIcon icon={faPen} className="me-1" />
                    Registro
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    <FontAwesomeIcon icon={faUser} className="me-1" />
                    Iniciar Sesión
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/cart">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-1" />
                    Carrito
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        
        {showChat && <ChatComponent />}

        <div style={{ position: 'fixed', bottom: '80px', right: '50px' }}>
          <button onClick={() => setShowChat(!showChat)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: '10px' }}>
            CHAT
          </button>
        </div>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/internet-plans" element={<InternetPlans />} />
            <Route path="/online-payments" element={<OnlinePayments />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/overdue-payments" element={<OverduePayments />} /> 
          </Routes>
        </main>

        <footer style={{ textAlign: 'center', padding: '20px', background: '#f0f0f0' }}>
          <FooterMenu />
          © 2024 Well Net. Todos los derechos reservados.
        </footer>
      </div>
    </Router>
  );
}

export default App;
