// components/Login.js
import React from 'react';

function Login() {
  return (
    <div>
      <h2>Iniciar Sesión</h2>
      {/* Formulario de inicio de sesión u otro contenido relacionado */}
    </div>
  );
}

export default Login;
