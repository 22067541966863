import React from 'react';
import { Link } from 'react-router-dom';

const FooterMenu = () => {
  const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    fontSize: '14px',
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* Segunda columna (Enlaces del menú superior) */}
      <ul style={{ listStyleType: 'none', marginRight: '20px' }}>
        <li><Link to="/" style={linkStyle}>Inicio</Link></li>
        <li><Link to="/products" style={linkStyle}>Productos</Link></li>
        <li><Link to="/internet-plans" style={linkStyle}>Planes de Internet</Link></li>
        <li><Link to="/online-payments" style={linkStyle}>Pagos en Línea</Link></li>
        {/* Agrega más elementos según sea necesario */}
      </ul>

      {/* Primera columna */}
      <ul style={{ listStyleType: 'none', marginRight: '20px' }}>
        <li><a href="/jobs" style={linkStyle}>Ofertas de trabajo</a></li>
        <li><a href="/apply" style={linkStyle}>Enviar solicitud</a></li>
        {/* Agrega más elementos según sea necesario */}
      </ul>

      {/* Tercera columna (Temas relacionados con el contacto) */}
      <ul style={{ listStyleType: 'none' }}>
        <li><a href="/contact" style={linkStyle}>Contacto</a></li>
        <li><a href="/support" style={linkStyle}>Soporte Técnico</a></li>
        <li><a href="/faq" style={linkStyle}>Preguntas Frecuentes</a></li>
        {/* Agrega más temas relacionados con el contacto según sea necesario */}
      </ul>
    </div>
  );
};

export default FooterMenu;
