// components/OnlinePayments.js
import React, { useState } from 'react';
import './estilos/OnlinePayments.css';

const OnlinePayments = () => {
  const [pendingPayments] = useState([
    { month: 'Enero', cycle: 'Ciclo 1', amountDue: 500 },
    { month: 'Febrero', cycle: 'Ciclo 2', amountDue: 600 },
    // Agrega más meses pendientes según sea necesario
  ]);

  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    amount: 0,
  });

  const handlePaymentSubmit = () => {
    // Lógica para procesar el pago
    console.log('Pago procesado:', paymentInfo);
  };

  return (
    <div className="container">
      <h2>Pagos en Línea</h2>

      <div className="payment-list">
        {/* Lista de meses pendientes con expresión del ciclo de facturación y monto */}
        {pendingPayments.map((payment, index) => (
          <div key={index} className="payment-item">
            <span>
              {`${payment.month} - ${payment.cycle} - Monto Pendiente: ${payment.amountDue} pesos`}
            </span>
            <span
              onClick={() => {
                setPaymentInfo({ ...paymentInfo, amount: payment.amountDue });
              }}
            >
              (Pagar)
            </span>
          </div>
        ))}
      </div>

      {/* Formulario de pago */}
      <div className="payment-form">
        <label>Número de tarjeta:</label>
        <input
          type="text"
          value={paymentInfo.cardNumber}
          onChange={(e) => setPaymentInfo({ ...paymentInfo, cardNumber: e.target.value })}
        />

        <label>Fecha de expiración:</label>
        <input
          type="text"
          value={paymentInfo.expirationDate}
          onChange={(e) => setPaymentInfo({ ...paymentInfo, expirationDate: e.target.value })}
        />

        <label>CVV:</label>
        <input
          type="text"
          value={paymentInfo.cvv}
          onChange={(e) => setPaymentInfo({ ...paymentInfo, cvv: e.target.value })}
        />

        <label>Monto a pagar:</label>
        <input type="text" value={paymentInfo.amount} readOnly />

        <button onClick={handlePaymentSubmit}>Pagar</button>
      </div>
    </div>
  );
};

export default OnlinePayments;
