// src/components/OverduePayments.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa Bootstrap CSS

const OverduePayments = () => {
  return (
    <div className="container text-center my-5">
      <div className="card">
        <div className="card-body">
          <h1 className="card-title">¡Tu cuenta tiene pagos pendientes!</h1>
          <p className="card-text">Para seguir disfrutando de nuestros servicios, por favor regulariza tu situación.</p>
          <div className="my-4">
            <img src="/images/motivational.jpg" alt="Motivational" className="img-fluid mb-3" />
            <p className="text-muted">"El primer paso hacia el éxito es pagar tus facturas."</p>
          </div>
          <button className="btn btn-primary" onClick={() => window.location.href = '/online-payments'}>
            Ir a Pagar
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverduePayments;
