// components/Products.js
import React, { useState, useEffect } from 'react';

function Products() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Simulación de carga asíncrona de productos (puedes reemplazarlo con una llamada a una API)
    const fetchData = async () => {
      // Simular una demora de 1 segundo (puedes eliminar esto en una implementación real)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Simular la obtención de datos de productos desde una API o base de datos
      const data = [
        { id: 1, name: 'Producto 1', description: 'Descripción del Producto 1', price: 20.99 },
        { id: 2, name: 'Producto 2', description: 'Descripción del Producto 2', price: 15.49 },
        { id: 3, name: 'Producto 3', description: 'Descripción del Producto 3', price: 25.99 },
        { id: 4, name: 'Producto 4', description: 'Descripción del Producto 4', price: 12.99 },
        { id: 5, name: 'Producto 5', description: 'Descripción del Producto 5', price: 18.99 },
        { id: 6, name: 'Producto 6', description: 'Descripción del Producto 6', price: 22.49 },
        { id: 7, name: 'Producto 7', description: 'Descripción del Producto 7', price: 30.99 },
        { id: 8, name: 'Producto 8', description: 'Descripción del Producto 8', price: 16.99 },
        { id: 9, name: 'Producto 9', description: 'Descripción del Producto 9', price: 19.99 },
        { id: 10, name: 'Producto 10', description: 'Descripción del Producto 10', price: 28.99 },
      ];

      setProducts(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Productos</h2>
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product">
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>Precio: ${product.price}</p>
            <button>Agregar al Carrito</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
