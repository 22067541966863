// components/Register.js
import React from 'react';

function Register() {
  return (
    <div>
      <h2>Registro</h2>
      {/* Formulario de registro u otro contenido relacionado */}
    </div>
  );
}

export default Register;
